<template>
  <div id="header">
    <div class="header-upper" :class="scrollFixed === true ? 'isFixed' : ''">
      <div class="auto-container">
        <div class="logo-box">
          <div class="logo">
            <!-- <img
              :src="site.logo"
              alt=""
              title=""
            > -->
            <img src="../../assets/logo.png" alt="">
          </div>
        </div>
        <div>
          <div class="main-menu">
            <ul class="navigation">
              <li class="current" v-for="(item, index) in navList" :key="index" @click="gotoPage(index)"><span
                  :class="{ active: index == isActive }">{{ item.title }}</span></li>
            </ul>
            <div class="userInfo" @click="login()">
              <p>登录</p>
              <img src="../../assets/tx.png" alt="">
            </div>
            <!-- <div class="icon-box">
              <Icon type="md-search" color="#fff" size="20" />
              <Icon type="md-menu" color="#fff" size="20" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <Modal v-model="loginModal" width="33%" footer-hide="true">
      <div class="loginModal">
        <p class="title">账号密码登录</p>
        <div class="con">
          <Input v-model="zh" class="input" placeholder="请输入账号">
          <template #prepend>
            <span>账号：</span>
          </template>
          </Input>
          <Input v-model="password" type="password" class="input mt-4" placeholder="请输入密码">
          <template #prepend>
            <span>密码：</span>
          </template>
          </Input>
          <!-- <div class="mt-4">
            <Checkbox v-model="isagree"><span class="tk">已阅读并同意<a
                  href="https://www.rayscloud.cn/instructions/aichatprivacy" target="_blank"
                  rel="noopener noreferrer">《用户协议》《隐私权政策》</a>
              </span></Checkbox>
          </div> -->
          <Button type="success" @click="login" long class="btn">登录</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import header from "./header.js";
export default header;
</script>

<style lang="less" scoped>
@import "./header.less";
</style>