<template>
  <div class="home">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // weburl: "https://www.rayscloud.cn/webapi",
    };
  },
};
</script>
<style lang="less" scoped></style>
