import axios from "axios";
export default {
  data() {
    return {
      weburl: "https://www.rayscloud.cn/webapi",
      webId: 2,
      site: {},
      navList: [],
      scrollFixed: false,
      isActive: 0,
      // cRout:'/'
      loginModal: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    login(){
      this.loginModal = true;
    },
    getSite() {
      axios.get(`${this.weburl}/api/Site/site?schid=3014`).then((res) => {
        if (res.status == 200) {
          if (res.data.data.total > 0) {
            this.site = res.data.data.data[0];
            this.getmenu(this.site.serId);
          }
        }
      });
    },
    ///获取菜单
    getmenu(serId) {
      localStorage.setItem("siteId", serId);
      axios
        .get(`${this.weburl}/api/Site/menu/list/` + serId + `/0`)
        .then((res) => {
          if (res.status == 200) {
            this.navList = res.data.data;
            // console.log('获取菜单:' + this.cRout)
          }
        });
    },
    gotoPage(index) {
      let data = Object.assign({}, this.navList[index]);
      if (data.title == "产品") {
        localStorage.setItem("menuId", 67);
        localStorage.setItem("childId", 63);
      } else {
        localStorage.setItem("menuId", data.serId);
      }
      this.isActive = index;
      this.$router.push(data.link);
    },
    handleScroll() {
      let _this = this;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop)
      if (scrollTop) {
        _this.scrollFixed = true;
      } else {
        _this.scrollFixed = false;
      }
    },
  },
  created() {
    // console.log(this.$route.path);
    // this.cRout = this.$route.path;
    // if (this.cRout && this.cRout.length > 1) {
    //     this.cRout = this.cRout.substr(1);
    // }
    // if(!localStorage.getItem("siteId")){
    //     this.getSite();
    //   }else{
    //       let siteId=localStorage.getItem("siteId");
    //       this.getmenu(siteId)
    //   }
    this.getSite();
  },
};
