import axios from "axios"
export default {
  data() {
    return {
      menulist: [],
      navlist: [],
      webId: 2,
      site: {},
      weburl: "https://www.rayscloud.cn/webapi",
      talkModal: false,
      certification: [
        {
          img: 'https://file.qingflow.com/assets/index/v2021/gaoxin.png',
          name: '高新技术企业认证'
        },
        {
          img: 'https://file.qingflow.com/assets/index/v2021/cseea.png',
          name: '双软企业认证'
        },
        {
          img: 'https://i.bmp.ovh/imgs/2022/05/23/789dd3e67e93ccf0.png',
          name: 'ISO9001认证'
        },
        {
          img: 'https://file.qingflow.com/assets/index/v2021/dengbaosan.png',
          name: '信息系统安全等级保护第二级'
        }
      ]
    }
  },
  methods: {
    getSite() {
      axios.get(`${this.weburl}/api/Site/site?schid=3014`)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.data.total > 0) {
              this.site = res.data.data.data[0];
              this.getmenu(this.site.serId);
            }
          }
        })
    },
    ///获取菜单
    getmenu(siteid) {
      localStorage.setItem("siteId", siteid);
      this.siteid = siteid;
      axios.get(`${this.weburl}/api/Site/menu/HomeRecom/` + siteid)
        .then((res) => {
          if (res.status == 200) {
            res.data.data.push({
              serId: 0,
              title: "服务支持",
              link: "support",
              parentId:51
            })
            this.menulist = res.data.data;
          }
        })
    },
    toCont(ite) {
      localStorage.setItem("childId", ite.serId);
      localStorage.setItem("menuId", ite.parentId);
      if (ite.type !== 'link') {
        this.$router.push({
          path: `${ite.link}?mid=${ite.serId}`
        });
      } else {
        window.open(ite.link, '_bank')
      }
    },
    ///获取菜单
    getchild(serId, index) {
      axios.get(`${this.weburl}/api/Site/Menu/list/${this.siteid}/${serId}`)
        .then((res) => {
          if (res.status == 200) {
            this.navlist[index] = res.data.data;
          }
        })
    },
    // 与我们一起讨论
    toTalk() {
      this.talkModal = true
    },
    ok() {
      this.talkModal = false
    }
  },
  created() {
    if (!localStorage.getItem("siteId")) {
      this.getSite();
    } else {
      let siteId = localStorage.getItem("siteId");
      this.getmenu(siteId)
    }
  }
}