<template>
  <div>
    <div class="btnCenter">
      <div class="btn-box" @click="toTalk">
        <span class="theme-btn btn-style-four"><span class="text">商务洽谈</span></span>
      </div>
    </div>
    <div id="footer">
      <div class="auto-container">
        <div class="discuss-section">
          <div class="inner-section">
            <!-- <div>
            <h2>让我们谈谈你的下个项目吧！</h2>
            <p>主要内容为商务洽谈，项目资讯等</p>
          </div> -->
            <ul>
              <li v-for="(item, index) in certification" :key="index">
                <img :src="item.img" alt="" />
                <div class="name">{{ item.name }}</div>
              </li>
            </ul>
            <Modal v-model="talkModal" :styles="{ top: '20px' }" width="80%">
              <img style="width: 100%" src="../../assets/talk.png" alt="" />
              <div slot="footer">
                <Button type="primary" @click="ok">确定</Button>
              </div>
            </Modal>
          </div>
        </div>

        <div class="widgets-section">
          <Row class="row" :gutter="15">
            <Col :xl="8" :lg="8" :md="12" :sm="24" :xs="24">
            <div class="footer-widget">
              <div class="logo">
                <img src="../../assets/logo.png" alt="" />
              </div>
              <div class="copyright">
                <a href="http://beian.miit.gov.cn/" target="_bank">
                  沪ICP备17034786号-11
                </a>
              </div>
              <div class="copyright">
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=沪公网安备 31010502006224号"
                  target="_bank">
                  <img src="../../assets/beian.png" alt="" /> 沪公网安备
                  31010502006224号
                </a>
              </div>
            </div>
            </Col>
            <Col :xl="10" :lg="10" :md="12" :sm="12" :xs="24" class="info">
            <Row class="row" :gutter="15">
              <Col :xl="10" :lg="10" :md="12" :sm="12" :xs="12" v-for="(item, index) in ['公司', '服务']" :key="index">
              <div class="footer-widget">
                <h2>{{ item }}</h2>
              </div>
              </Col>
              <Col :xl="10" :lg="10" :md="12" :sm="24" :xs="12" v-for="ite in menulist" :key="ite.serId">
              <div class="footer-widget">
                <ul class="list">
                  <li @click="toCont(ite)">
                    {{ ite.title }}
                  </li>
                </ul>
              </div>
              </Col>
            </Row>
            </Col>
            <Col :xl="6" :lg="6" :md="12" :sm="24" :xs="24">
            <div class="footer-widget nocur">
              <h2>联系我们</h2>
              <ul class="list">
                <li>地址：上海市长宁区泉口路17号502室</li>
                <li>电话：021-62620100</li>
                <li>商务合作：yaoning@xiaobangedu.com</li>
                <li>客服邮箱：service@xiaobangedu.com</li>
              </ul>
            </div>
            </Col>
          </Row>
        </div>

        <div class="codeBox">
          <Row class="row" :gutter="15">
            <Col :xl="6" :lg="8" :md="12" :sm="24" :xs="24">
            <div class="codeImg">
              <img src="../../assets/xbjy.jpg" alt="">
              <p>校帮教育公众号</p>
            </div>
            </Col>
            <Col :xl="6" :lg="8" :md="12" :sm="24" :xs="24">
            <div class="codeImg">
              <img src="../../assets/xb.jpg" alt="">
              <p>校帮小程序</p>
            </div>
            </Col>
            <Col :xl="6" :lg="8" :md="12" :sm="24" :xs="24">
            <div class="codeImg">
              <img src="../../assets/xzl.jpg" alt="">
              <p>校智联小程序</p>
            </div>
            </Col>
            <Col :xl="6" :lg="8" :md="12" :sm="24" :xs="24">
            <div class="codeImg">
              <img src="../../assets/dy.jpg" alt="">
              <p>校帮抖音号</p>
            </div>
            </Col>
          </Row>
        </div>
      </div>
      <div class="rights">
        上海曜宁信息科技有限公司 2017-2024 <br />
        Copyright © All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import footer from "./footer.js";
export default footer;
</script>

<style lang="less" scoped>
@import "./footer.less";
</style>